.carousel-control-prev{height: 100px;;
    color:  #FF9A4A;
    font-size: 30px;
    font-weight: 900;
margin:auto}
.carousel-control-prev:hover{height: 2100px;
    color:  #FF9A4A;
    font-size: 30px;
    font-weight: 900;
margin:auto}
.carousel-control-next{height: 100px; color:  #FF9A4A;
    font-size: 30px;
    font-weight: 900;
margin:auto
}
.carousel-control-next:hover{height: 100px; color:  #FF9A4A;
    font-size: 30px;
    font-weight: 900;
margin:auto
}