.clear-filter[filter-color="blue"] {
  @include linear-gradient(rgba($black-color, 0.4), #ff9a4a);
  // @include linear-gradient(black, #ff9a4a);
}
[data-background-color="blue"] {
  background-color: $info-color;
}
.section-nucleo-icons .icons-container i:nth-child(6) {
  color: $info-color;
}
.page-header {
  &.page-header-small {
    min-height: 60vh;
    max-height: 640px;
  }
}
