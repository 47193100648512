.inputRounded .MuiOutlinedInput-root {
  border-radius: 20px;
  border-color: black;
  /* margin-top: 50px; */
  display: flex;
  text-align: center;

  color: black;
}
.FieldText {
  border-radius: 20px;
  border-color: black;
  /* margin-top: 50px; */
}
